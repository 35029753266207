import { Link } from 'gatsby';
import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import PageSection from '../components/ui/PageSection';
import Row from '../components/ui/Row';
import Spacing from '../components/ui/Spacing';
import Text from '../components/ui/Text';
import image from '../images/404.png';

const NotFoundPage = () => (
  <MarketingLayout>
    <Spacing top={5}>
      <PageSection>
        <Row even gutter="medium" verticalCentered reversed>
          <div>
            <img alt="Relax" src={image} />
          </div>
          <div>
            <h2>
              <Text darker>You look lost.</Text>
            </h2>
            <Spacing top={4}>
              Were you trying to get some work done?
              <br />
              This ferry will take you back.
            </Spacing>
            <Spacing top={5}>
              <Link to="/">
                <Text darker bold>
                  Go Home{' '}
                  <Text inline link>
                    →
                  </Text>
                </Text>
              </Link>
            </Spacing>
          </div>
        </Row>
      </PageSection>
    </Spacing>
  </MarketingLayout>
);

export default NotFoundPage;
